export const OPEN = 'open';
export const IN_PROGRESS = 'in_progress';
export const ACCEPTED = 'accepted';
export const COMPLETED = 'completed';
export const DECLINED = 'declined';
export const CANCELLED = 'cancelled';
export const CLOSED = 'closed';
export const APPROVED = 'approved';
export const PENDING_CUSTOMER_APPROVAL = 'pending_customer_approval';
export const PENDING_DC_APPROVAL = 'pending_dc_approval';
export const OPERATIONS = 'operations';
export const FACILITIES = 'facilities';
export const ENABLEMENT = 'Enablement';

export const SECURITY_IN_PROGRESS = 'security_in_progress';
export const SECURITY_COMPLETE = 'security_complete';
export const PENDING = 'pending';
export const CURRENTLY_ON_SITE = 'currently_on_site';

export const CHECKED_IN = 'checked_in';
export const CHECKED_OUT = 'checked_out';
export const UNDER_INVESTIGATION = 'under_investigation';

export const INCIDENT_DETECTED = 'incident_detected';
export const RESTORED = 'restored';
export const RESOLVED = 'resolved';
export const EXTERNAL = 'external';
export const INTERNAL = 'internal';

export const AUDIT_HISTORY_ATTRIBUTES = {
  access_list_site_visitors: 'Access List Site Visitors',
  access_reasons: 'Access Reason',
  additional_site_visitors: 'Additional Site Visitors',
  access_list_non_aal_site_visitors: 'Non AAL Site Visitors',
  access_list_non_aal_site_engineers: 'Non AAL Site Engineers',
  change_request_number: 'Change Request Number',
  customer: 'Customer',
  datacentre: 'Datacentre',
  datacentre_access_zones: 'Additional Access Zones',
  description: 'Description',
  devices: 'Devices',
  from_date: 'From Date',
  notes: 'Notes',
  rack_access_zone_ids: 'Rack Access Zones',
  racks: 'Racks',
  reason_for_access: 'Reason For Access',
  reason_for_cancel: 'Reason For Cancel',
  reason_for_decline: 'Reason For Decline',
  security_notes: 'Security Notes',
  security_status: 'Security Status',
  status: 'Status',
  Status: 'Status',
  title: 'Title',
  to_date: 'To Date',
  risk_assessment: 'Risk Assessment Status',
  customer_approval: 'Customer Approval Status',
  risk: 'Risk',
  approver_email: 'Approver Email',
  datacentre_to_perform: 'Datacentre To Perform',
  access_request_number: 'Access Request Number',
  access_request_created_by: 'Access Request Created By',
  duration: 'Duration',
  internal_comments: 'Internal Comments',
  scheduled_date: 'Scheduled Date',
  end_date: 'End Date',
  internal_group: 'Internal Group',
  additional_engineers: 'Additional Engineers',
  access_list_site_engineers: 'Access List Site Engineers',
  power_check_attachment: 'Power Check Attachment',
  post_change_audit_attachment: 'Post Change Audit Attachment',
  post_change_audit_image: 'Post Change Audit Image',
  power_check_calculation_table: 'Power Check Calculation Table',
  power_check: 'Power Check Status',
  post_change_audit: 'Post Change Audit Status',
  manual_power_check: 'Manual Power Check Status',
  change_request_actions: 'Change Request Actions',
  billing_code: 'Billing Code',
  estimated_date: 'Estimated Date',
  billing_cost: 'Billable Amount',
  service_request_actions: 'Service Request Actions',
  internal_assignee_name: 'Ticket Assignee Name',
  internal_assignee_email: 'Ticket Assignee Email',
  first_approver: 'Approver Email',
  risk_comment: 'Risk Comments',
  billing_details: 'Billing Details',
  service_request_attachment: 'Service Request Attachment',
  change_request_attachment: 'Change Request Attachment',
  comments: 'Comments',
  consumables_supplied: 'Consumables Supplied',
  reason_for_late_check_in: 'Reason for Late Check In',
  peer_reviewer_id: 'Power Check Peer Reviewer',
  peer_reviewer_name: 'Power Check Peer Reviewer Name',
  peer_reviewer_email: 'Power Check Peer Reviewer Email',
  second_approver: 'Second Approver Email',
  additional_approver_email: 'Additional Approver Email',
  ignore_image: 'Rack Image Restricted',
  site_visitors_notified: 'Notify all site visitors'
};

export const AUDIT_HISTORY_INCIDENT_ATTRIBUTES = {
  additional_customers: 'Additional Customers',
  briefing_url: 'Breifing URL',
  bu_owner: 'BU Owner',
  category: 'Category',
  datacentre_id: 'Datacentre',
  description: 'Description',
  detection_date: 'Detection Date',
  estimated_date: 'Estimated Date',
  incident_action: 'Incident Action',
  incident_updates: 'Incident Updates',
  priority: 'Priority',
  reason_for_action: 'Incident Action Comments',
  resolution_plan: 'Resolution Plan',
  restoration_description: 'Restoration Description',
  resolved_date: 'Resolved Date',
  status: 'Status',
  title: 'Email Header',
  incident_request_attachment: 'Incident Request Attachment',
  include_ddc: 'Notify Datacom Data Centres Staff',
  automatic_updates: 'Automatic Update Notification',
  update_time: 'Incident Automatic Update Options',
  updates: 'Impact Type Data Centre',
  impact: 'Impact Type',
};

export const SERVICE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [PENDING_CUSTOMER_APPROVAL]: 'Pending Customer Approval',
  [ACCEPTED]: 'Accepted',
  [COMPLETED]: 'Completed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
};

export const NEW_SERVICE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [PENDING_CUSTOMER_APPROVAL]: 'Pending Customer Approval',
  [ACCEPTED]: 'Accepted',
};

export const CHANGE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [PENDING_CUSTOMER_APPROVAL]: 'Pending Customer Approval',
  [PENDING_DC_APPROVAL]: 'Pending DC Approval',
  [APPROVED]: 'Approved',
  [COMPLETED]: 'Completed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
  [CLOSED]: 'Closed',
};

export const INTERNAL_TASK_STATUSES = {
  pending: 'Pending',
  completed: 'Completed',
  failed: 'Failed',
};

export const POWER_CHECK_STATUSES = {
  pending: 'Pending',
  passed: 'Passed',
  failed: 'Failed',
};

export const POWER_CHECK_MANUAL_STATUSES = {
  notset: 'Not Set',
  pending: 'Pending',
  passed: 'Passed',
  failed: 'Failed',
  not_applicable: 'Exempted',
};

export const NEW_CHANGE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [PENDING_CUSTOMER_APPROVAL]: 'Pending Customer Approval',
  [PENDING_DC_APPROVAL]: 'Pending DC Approval',
};

export const INCIDENT_REQUEST_STATUSES = {
  [INCIDENT_DETECTED]: 'Incident Detected',
  [IN_PROGRESS]: 'In Progress',
  [CLOSED]: 'Closed',
  [RESTORED]: 'Restored',
  [RESOLVED]: 'Resolved',
};

export const NEW_INCIDENT_REQUEST_STATUSES = {
  [INCIDENT_DETECTED]: 'Incident Detected',
  [IN_PROGRESS]: 'In Progress',
};

export const ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
  [CLOSED]: 'Closed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
};

export const AUDIT_HISTORY_ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
  [CLOSED]: 'Closed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
  [APPROVED]: 'Approved',
};

export const ACCESS_REQUEST_SECURITY_STATUSES = {
  [PENDING]: 'Pending',
  [CURRENTLY_ON_SITE]: 'Currently On Site',
  [SECURITY_COMPLETE]: 'Complete',
};
export const ACCESS_REQUEST_INIT_STATUSES = {
  [PENDING]: 'Pending',
};
export const ACCESS_REQUEST_PENDING_STATUSES = {
  [PENDING]: 'Pending',
  [CURRENTLY_ON_SITE]: 'Currently On Site',
};
export const ACCESS_REQUEST_ON_SITE_STATUSES = {
  [CURRENTLY_ON_SITE]: 'Currently On Site',
  [SECURITY_COMPLETE]: 'Complete',
};
export const ACCESS_REQUEST_COMPLETE_STATUSES = {
  [SECURITY_COMPLETE]: 'Complete',
};

export const NEW_ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
};

export const MAINTENANCE_STATUSES = {
  completed: 'Completed',
  scheduled: 'Scheduled',
};

// access reason options
export const ACCESS_REASON_STATUSES = {
  rack_access: 'Rack Access',
  device_install: 'Device Install',
  device_removal: 'Device Removal',
  tour: 'Tour',
  build_room: 'Build Room',
  induction: 'Induction',
  contractor_visit: 'Contractor Visit',
  other: 'Other',
};

// BU owner options
export const BU_OWNER_OPTIONS = {
  facilities: 'Facilities',
  operations: 'Operations',
  enablement: 'Enablement',
  auditing_compliance: 'Auditing/Compliance',
  health_and_safety: 'Health and safety',
  other: 'Other',
};

// Category options
export const CATEGORY_OPTIONS = {
  safety: 'Safety',
  security: 'Security',
  network: 'Network',
  building: 'Building',
  power: 'Power',
  customer: 'Customer',
  other: 'Other',
};

export const INCIDENT_ACTIONS = {
  incident_under_investigation: 'Incident under Investigation',
  technician_coming_to_site: 'Technician coming to site',
  troubleshooting_issue: 'Troubleshooting issue',
  restoration_in_progress: 'Restoration in progress',
  services_restored: 'Services restored',
  incident_resolved: 'Incident resolved',
  incident_downgraded: 'Incident downgraded',
  other: 'Other',
};

export const INCIDENT_IMPACT_TYPES = {
  datacentre: 'Data Centre',
  data_floor: 'Data Floor',
  power_module: 'Power Module',
  rack: 'Rack',
  asset: 'Asset',
  location: 'Location',
};

export const CONTACT_TYPES = {
  approver: 'Approver',
  billing: 'Billing',
  maintenance: 'Maintenance',
  reporting: 'Reporting',
  site_access: 'Site Access',
  tape_management: 'Tape Management',
};

export const NOTIFICATION_FILTER_TYPES = {
  access_request: 'Access Request',
  service_request: 'Service Request',
  change_request: 'Change Request',
  maintenance: 'Maintenance',
  manual: 'Manual',
  incident_request: 'Incident',
};

export const NOTIFICATION_TYPES = {
  datacentre: 'Data Centre',
  customer: 'Customer',
  data_floor: 'Data Floor',
  power_module: 'Power Module',
  rack: 'Rack',
  asset: 'Asset',
  location: 'Location',
};

export const DOCUMENT_TYPE = {
  monthly_report: 'Monthly Report',
  newsletter: 'Newsletter',
  policy: 'Policy',
};

export const KEY_REQUEST_STATUSES = {
  [CHECKED_IN]: 'Checked In',
  [CHECKED_OUT]: 'Checked Out',
  [UNDER_INVESTIGATION]: 'Under Investigation',
};

export const KEY_REQUEST_CO_STATUSES = {
  [CHECKED_IN]: 'Checked In',
  [UNDER_INVESTIGATION]: 'Under Investigation',
};

export const NEW_KEY_REQUEST_STATUSES = {
  [CHECKED_OUT]: 'Checked Out',
};

export const INTERNAL_GROUPS = {
  [ENABLEMENT]: 'Enablement',
  [FACILITIES]: 'Facilities',
  [OPERATIONS]: 'Operations',
};

export const CUSTOMER_DB_TEST = 'https://dc-custdb-sys.datacomdev.co.nz/Administration';
export const CUSTOMER_DB_UAT = 'https://dc-custdb-uat.datacomdev.co.nz/Administration';
export const CUSTOMER_DB_PRODUCTION = 'https://customerdatabase.datacentres.datacom.net.nz/Administration';

export const DATE_FORMAT = 'll';
export const DATE_TIME_FORMAT = 'llll';
export const DATE_TIME_DRAWER_FORMAT = 'DD/MM/YYYY h:mm a z';

export const NOTES_MAX_LENGTH = 4000;
export const NOTES_MAX_CHARACTER = 500;

export const ALL_OR_NO_SELECTION = [];

export const ACCESS_REQUEST_PRIORITY = [
  'low', 'normal', 'high', 'critical',
];

export const INCIDENT_REQUEST_PRIORITY = [
  'low', 'medium', 'high', 'critical', 'informational',
];

export const INTERNAL_TASK_RISK = [
  'low', 'medium', 'high',
];

export const INCIDENT_TICKET_TYPE = [
  'internal', 'external',
];

export const CHANGE_ACTION_TYPE = [
  'install_device', 'remove', 'add_remove_child', 'relocate', 'replace_new', 'replace_identical', 'deploy_rack', 'decom_rack', 'other',
];

export const POWER_TYPE = [
  'single_phase', 'two_x_single_phase', 'three_phase',
];

export const LOCK_TYPE = [
  'none', 'master', 'slave', 'single', 'shared', 'electronic_single', 'manual',
];

export const RACK_HEIGHT = [
  '52U', '48U', 'half_rack', 'quarter_rack', 'other',
];

export const WIDTH_TYPE = [
  'standard_(600mm)', 'oversized',
];

export const SERVICE_ACTION_TYPE = [
  'cross_connection',
  'internal_patching',
  'install_device',
  'remove_device',
  'replace_device',
  'relocate_device',
  'load_tape',
  'unload_tape',
  'check_in_tape',
  'check_out_tape',
  'tape_movement_other',
  'cable_tracing',
  'device_inspection',
  'restart_device',
  'device_other',
  'install_device_component',
  'remove_device_component',
  'replace_device_component',
  'device_component_other',
  'rack_inspection',
  'rack_audit',
  'rack_escort',
  'other',
];
export const ACCESS_CONTACT_MOJ = 'Contact(s) are missing valid MOJ';

export const INTERNAL_GROUP = [
  'enablement', 'facilities', 'operations',
];

export const SERVICE_INTERNAL_GROUP = [
  'operations', 'enablement', 'facilities',
];

export const RACK_FACE = [
  'front', 'rear',
];

export const INCIDENT_TICKET_TYPES = {
  internal: 'Internal',
  external: 'External',
};

export const SERVICE_ACTION_TYPES = {
  cross_connection: 'Cross Connection - Change Request',
  internal_patching: 'Internal Patching',
  install_device: 'Install Device',
  remove_device: 'Remove Device',
  replace_device: 'Replace Device',
  relocate_device: 'Relocate Device',
  load_tape: 'Load Tape',
  unload_tape: 'Unload Tape',
  check_in_tape: 'Check-in Tape',
  check_out_tape: 'Check-out Tape',
  tape_movement_other: 'Tape Movement - Other',
  cable_tracing: 'Cable Tracing',
  device_inspection: 'Device Inspection',
  restart_device: 'Restart Device',
  device_other: 'Device - Other',
  install_device_component: 'Install Device Component',
  remove_device_component: 'Remove Device Component',
  replace_device_component: 'Replace Device Component',
  device_component_other: 'Device Component - Other',
  rack_inspection: 'Rack Inspection',
  rack_audit: 'Rack Audit',
  rack_escort: 'Rack Escort',
  other: 'Other',
};

export const SERVICE_ACTION_TYPES_DROPDOWN = {
  cabling: {
    internal_patching: 'Internal Patching',
  },
  devices: {
    install_device: 'Install',
    remove_device: 'Remove',
    relocate_device: 'Relocate',
    replace_device: 'Replace',
    device_other: 'Other',
  },
  device_components: {
    install_device_component: 'Install Device Component',
    remove_device_component: 'Remove Device Component',
    replace_device_component: 'Replace Device Component',
    device_component_other: 'Other',
  },
  tapes: {
    check_in_tape: 'Check-in',
    check_out_tape: 'Check-out',
    load_tape: 'Load',
    unload_tape: 'Unload',
    tape_movement_other: 'Other',
  },
  services: {
    cable_tracing: 'Cable Tracing',
    device_inspection: 'Device Inspection',
    rack_audit: 'Rack Audit',
    rack_escort: 'Rack Escort',
    rack_inspection: 'Rack Inspection',
    restart_device: 'Restart Device',
  },
  cabling_change_request: {
    cross_connection: 'Cross Connection - Change Request',
  },
  other: {
    other: 'Other',
  },
};

export const SERVICE_ACTION_CATEGORY = {
  cabling: 'Cabling',
  devices: 'Devices',
  device_components: 'Device Components',
  tapes: 'Tapes',
  services: 'Services',
  cabling_change_request: 'Cabling Change Request',
  other: 'Other'
}

export const CABLE_TYPES = {
  multi_mode_fibre: 'Multi-Mode Fibre', 
  single_mode_fibre_simplex: 'Single-Mode Fibre (Simplex)', 
  single_mode_fibre_duplex: 'Single-Mode Fibre (Duplex)', 
  cat5e: 'CAT5e', 
  cat6: 'CAT6', 
  other: 'Other',
};

export const INTERNAL_PATCHING_TYPES = {
  patch: 'Patch', 
  remove: 'Remove', 
  single_sided_patch: 'Single Sided Patch', 
  single_sided_remove: 'Single Sided Remove', 
};

export const ACTION_TYPES = {
  remove: 'Remove Device',
  relocate: 'Relocate Device',
  relocate_install: 'Relocate Device',
  relocate_remove: 'Relocate Device',
  replace_new: 'Replace Device - New',
  add_remove_child: 'Add/Remove Child',
  replace_identical: 'Replace Device - Identical',
  install_device: 'Install Device',
  other: 'Other',
  deploy_rack: 'Deploy Rack',
  decom_rack: 'Decom Rack',
};

export const POWER_TYPES = {
  single_phase: 'Single Phase',
  two_x_single_phase: '2X Single Phase',
  three_phase: 'Three Phase',
};

export const LOCK_TYPES = {
  none: 'None',
  master: 'Master',
  slave: 'Slave',
  single: 'Single',
  shared: 'Shared',
  electronic_single: 'Electronic Single',
  manual: 'Manual',
};

export const RACK_HEIGHTS = {
  half_rack: 'Half Rack',
  quarter_rack: 'Quarter Rack',
  other: 'Other',
};

export const ESCORT_REQUIRED_STATUS = [
  'yes', 'no',
];

export const RACKED_UNRACKED = [
  'racked', 'unracked',
];

export const ACCESS = 'ACCESS';
export const ACCESS_SECURITY = 'ACCESS_SECURITY';
export const CHANGE = 'CHANGE';
export const ADDITIONAL = 'ADDITIONAL';
export const NEW_ACTION = 'NEW_ACTION';
export const ADDITIONAL_ENGINEER = 'ADDITIONAL_ENGINEER';
export const ADDITIONAL_REQUESTER = 'ADDITIONAL_REQUESTER';
export const ADDITIONAL_CUSTOMER = 'ADDITIONAL_CUSTOMER';
export const NEW_UPDATE = 'NEW_UPDATE';

export const SERVICE = 'SERVICE';

export const SIGN_OUT = 'signOut';
export const SIGN_IN = 'signIn';

export const SECURITY = 'Security';
export const OPERATIONAL = 'Operational';
export const CUSTOMER_TEAM = 'Customer_Team';

export const SECURITY_TEAM_USER = 'SECURITY_TEAM_USER';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const SUPERVISOR = 'SUPERVISOR';
export const VIEW_ONLY = 'VIEW_ONLY';

export const KEY = 'KEY';

export const API_NAME = process.env.REACT_APP_AWS_API_NAME;

export const maxCharacterLength = length => `Max length of ${length} characters reached`;
export const fieldRequired = field => `${field} is required`;
export const URLformatValidation = url => `${url} is invalid`;
export const invalidBeforeCurrentDateTime = (fieldName, dateTime) => (
  `${fieldName} date time cannot before or be the same as ${dateTime}`
);

export const TO_DATE_AFTER_FROM_DATE = 'End date time must be after Scheduled the date time';
export const FROM_DATE_BEFORE_TO_DATE = 'Schedule date time must be before the End date time';
export const FROM_DATE_IN_THE_PAST = 'Requested date time must be after current date time';
export const STATUS_INVALID = 'Request status is invalid';
export const REQUESTER_EMAIL_INVALID = 'Invalid email format';
export const RACKS_REQUIRED = 'Select at least one rack';
export const ACCESS_ZONES_REQUIRED = 'Select at least one access zone for each rack';
export const ADDITIONAL_CONTACT_NOT_REQUIRED = 'Request has non access list contacts';
export const ACCESS_REASON_REQUIRED = 'Select one or more access reasons';
export const ACCESS_REASON_OTHER = 'Other Access Reason chosen - Manually validate';
export const ACCESS_ZONES_CONTRACTOR_VISIT_REQUIRED = 'Contractor Visit requires are least one Additional Access Zone.';
export const ACCESS_REASON_DECLINED = 'Access Request declined - Reason needed';
export const ACCESS_REASON_CANCELLED = 'Access Request cancelled - Reason needed';
export const KEY_REQUIRED = 'Please select at least one key from Available Keys - Key(s) being Checked Out or Under Investigation by another ticket will not be available.';
export const REQUESTER_REQUIRED = 'Select or Add at least one requester';
export const CHANGE_REQUEST_NUMBER_REQUIRED = 'Change Request Number Required';
export const CHANGE_REQUEST_NUMBER_MISSING = 'Change Request Number is Missing';
export const RACKS_INVALID = 'One or more of your racks has an invalid date';
export const PRIORITY_INVALID = 'Priority is required';
export const CONTACT_REQUIRED = 'Must have at least one Access contact or Additional contact or Non AAL contact';
export const ENGINEER_REQUIRED = 'Must have at least one Access engineer or Additional engineer or Non AAL engineer';
export const ACCESS_CONTACT_REQUIRED = 'Must have at least one Access contact';
export const KEYS_REQUIRED = 'Must have at least one Key';
export const REQUESTERS_REQUIRED = 'Must have at least one Requester';
export const ESCORT_NAME_REQUIRED = 'Must have Escort Name if Escort Required is Yes for Additional contact';
export const ACCESS_CONTACT_SITE_ACCESS = 'Contact(s) do not have the attribute site access';
export const ACCESS_CONTACT_INDUCTION = 'Contact(s) are missing valid induction';
export const COMMENTS_REQUIRED = 'Requester Email is required';
export const EQUINIX_NUMBER_REQUIRED = 'Equinix Access Number is required';
export const AIRTRUNK_NUMBER_REQUIRED = 'AirTrunk Access Number is required';

export const ACCESS_REQUEST_SECURITY_FORM_PERMISSIONS = {
  [PENDING]: {
    permittedStatuses: [CURRENTLY_ON_SITE],
    unchangeableFields: [
    ],
  },
  [CURRENTLY_ON_SITE]: {
    permittedStatuses: [SECURITY_COMPLETE],
    unchangeableFields: [
    ],
  },
  [SECURITY_COMPLETE]: {
    permittedStatuses: [],
    unchangeableFields: [
      'security_status',
      'security_notes',
    ],
  },
};

export const ACCESS_REQUEST_FORM_PERMISSIONS = {
  in_progress: {
    permittedStatuses: [IN_PROGRESS, ACCEPTED, CANCELLED, DECLINED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  accepted: {
    permittedStatuses: [CANCELLED, DECLINED, CLOSED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  declined: {
    permittedStatuses: [IN_PROGRESS, ACCEPTED, CLOSED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'reason_for_access',
      'access_reasons', // Access Reason Options
      'from_date', // Scheduled Date/Time
      'to_date', // End Date/Time
      'racks',
      'change_request_number',
      'access_list_site_visitors', // Access Contacts
      'additional_site_visitors', // Additional Contacts
      'airtrunk_access_number',
      'equinix_access_number',
    ],
  },
  cancelled: {
    permittedStatuses: [IN_PROGRESS, ACCEPTED, CLOSED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'reason_for_access',
      'access_reasons',
      'from_date', // Scheduled Date/Time
      'to_date', // End Date/Time
      'racks',
      'change_request_number',
      'access_list_site_visitors', // Access Contacts
      'additional_site_visitors', // Additional Contacts
      'airtrunk_access_number',
      'no_racks_override',
      'site_visitors_notified',
      'equinix_access_number',
    ],
  },
  closed: {
    permittedStatuses: [],
    unchangeableFields: [
      'status',
      'customer',
      'datacentre',
      'requester',
      'reason_for_access',
      'access_reasons',
      'from_date', // Scheduled Date/Time
      'to_date', // End Date/Time
      'racks',
      'change_request_number',
      'access_list_site_visitors', // Access Contacts
      'additional_site_visitors', // Additional Contacts
      'no_racks_override',
      'site_visitors_notified',
    ],
  },
};

export const KEY_REQUEST_FORM_PERMISSIONS = {
  checked_in: {
    permittedStatuses: [],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'datacentre_key_lockers',
      'status'
    ],
  },
  under_investigation: {
    permittedStatuses: [CHECKED_IN],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'datacentre_key_lockers',
    ],
  },
  checked_out: {
    permittedStatuses: [CHECKED_IN, UNDER_INVESTIGATION],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'datacentre_key_lockers',
    ],
  },
};

export const SERVICE_REQUEST_FORM_PERMISSIONS = {
  [OPEN]: {
    permittedStatuses: [ACCEPTED, CANCELLED, DECLINED, PENDING_CUSTOMER_APPROVAL],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  [PENDING_CUSTOMER_APPROVAL]: {
    permittedStatuses: [ACCEPTED, CANCELLED, DECLINED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  [ACCEPTED]: {
    permittedStatuses: [CANCELLED, DECLINED, COMPLETED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  [DECLINED]: {
    permittedStatuses: [ACCEPTED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'title',
      'description',
      'devices',
      'from_date', // Scheduled Date/Time
      'racks',
      'change_request_number',
      'no_racks_override',
      'approver_email',
      'billing_cost',
      'billing_code',
      'end_date',
    ],
  },
  [CANCELLED]: {
    permittedStatuses: [ACCEPTED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'title',
      'description',
      'devices',
      'from_date', // Scheduled Date/Time
      'racks',
      'change_request_number',
      'no_racks_override',
      'approver_email',
      'billing_cost',
      'billing_code',
      'end_date',
    ],
  },
  [COMPLETED]: {
    permittedStatuses: [],
    unchangeableFields: [
      'status',
      'customer',
      'datacentre',
      'requester',
      'title',
      'description',
      'devices',
      'from_date', // Scheduled Date/Time
      'racks',
      'change_request_number',
      'no_racks_override',
      'approver_email',
      'billing_code',
      'end_date',
    ],
  },
};

// Incident Update options
export const INCIDENT_UPDATE_OPTIONS = {
  fifteen: '15 Minutes',
  thirty: '30 Minutes',
  sixty: '60 Minutes',
};

export const INCIDENT_REQUEST_FORM_PERMISSIONS = {
  [INCIDENT_DETECTED]: {
    permittedStatuses: [INCIDENT_DETECTED, IN_PROGRESS],
    unchangeableFields: [
      'datacentre',
    ],
  },
  [IN_PROGRESS]: {
    permittedStatuses: [RESTORED, IN_PROGRESS],
    unchangeableFields: [
      'datacentre',
    ],
  },
  [RESTORED]: {
    permittedStatuses: [IN_PROGRESS, RESTORED, RESOLVED],
    unchangeableFields: [
      'datacentre',
      'title',
      'description',
      'devices',
      'priority',
    ],
  },
  [RESOLVED]: {
    permittedStatuses: [CLOSED],
    unchangeableFields: [
      'datacentre',
      'title',
      'description',
      'priority',
    ],
  },
  [CLOSED]: {
    permittedStatuses: [],
    unchangeableFields: [
      'status',
      'datacentre',
      'title',
      'description',
      'priority',
      'restoration_description',
      'resolution_plan',
    ],
  },
};

export const CHANGE_REQUEST_FORM_PERMISSIONS = {
  [OPEN]: {
    permittedStatuses: [OPEN, PENDING_DC_APPROVAL, PENDING_CUSTOMER_APPROVAL, CANCELLED],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [PENDING_CUSTOMER_APPROVAL]: {
    permittedStatuses: [APPROVED, PENDING_DC_APPROVAL],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [PENDING_DC_APPROVAL]: {
    permittedStatuses: [APPROVED],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [APPROVED]: {
    permittedStatuses: [DECLINED, CANCELLED, CLOSED, COMPLETED],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [COMPLETED]: {
    permittedStatuses: [CLOSED],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [DECLINED]: {
    permittedStatuses: [],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [CANCELLED]: {
    permittedStatuses: [],
    unchangeableFields: [
      'datacentre',
      'customer',
    ],
  },
  [CLOSED]: {
    permittedStatuses: [],
    unchangeableFields: [
      'status',
      'datacentre',
      'customer',
    ],
  },
};
